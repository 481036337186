*,
*:before,
*:after {
	box-sizing: border-box;
	position: relative;
}

body {
	@apply w-full;
}

.nexment-admin-badge {
	display: none !important;
}

.nexment-header {
	margin-bottom: 5px !important;
}

.prose ol {
	padding-left: 35px;
	display: block;
	margin: -0.5rem 0;
	overflow: hidden;
	overflow-x: auto;
	line-height: 1.6;
	color: #212529;
	@apply dark:text-white;
	list-style-type: none;
	counter-reset: item;
}

.prose ol li {
	margin-bottom: 10px;
	padding-left: 9px !important;
}

.prose ol li:before {
	box-sizing: border-box;
	border: 0 solid #999;
	margin-top: 4px;
	transform: translateY(-1px);
	margin-left: -44px !important;
	margin-right: 14px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 19px;
	width: 30px;
	background-color: rgba(195, 218, 254, 0.43);
	border-radius: 9999px;
	color: rgba(85, 134, 210, 0.8);
	@apply dark:text-blue-400;
	@apply dark:bg-blue-900;
	font-size: 11.4px;
	font-weight: 700;
	content: counter(item);
	counter-increment: item;
}

.prose p {
	overflow: hidden;
	overflow-x: auto;
	@apply rounded-md;
}

.prose blockquote {
	background-repeat: no-repeat;
	background-size: 8%;
	background-position: 12px 6px;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0H24V24H0z'/%3E%3Cpath d='M21 3c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H6.455L2 22.5V4c0-.552.448-1 1-1h18zm-1 2H4v13.385L5.763 17H20V5zm-9.485 2.412l.447.688c-1.668.903-1.639 2.352-1.639 2.664.155-.02.318-.024.48-.009.902.084 1.613.825 1.613 1.745 0 .966-.784 1.75-1.75 1.75-.537 0-1.05-.245-1.374-.59-.515-.546-.792-1.16-.792-2.155 0-1.75 1.228-3.318 3.015-4.093zm5 0l.447.688c-1.668.903-1.639 2.352-1.639 2.664.155-.02.318-.024.48-.009.902.084 1.613.825 1.613 1.745 0 .966-.784 1.75-1.75 1.75-.537 0-1.05-.245-1.374-.59-.515-.546-.792-1.16-.792-2.155 0-1.75 1.228-3.318 3.015-4.093z' fill='rgba(119,119,119,0.34)'/%3E%3C/svg%3E");
	display: block;
	margin: 20px 0px;
	width: 100%;
	height: auto;
	font-size: 16px;
	font-style: normal;
	padding: 15px 20px 15px 80px;
	color: #777;
	@apply rounded-r-md bg-gray-50 dark:border-gray-500 dark:bg-gray-700;
}

.prose blockquote p {
	margin: 0px;
	color: #777 !important;
	@apply before:content-none after:content-none;
}

.dark .prose blockquote p {
	margin: 0px;
	@apply !text-gray-300;
}

.prose img {
	@apply m-0 rounded-md dark:bg-slate-50;
}

.prose h1,
h2,
h3,
h4,
h5 {
	@apply tracking-wide;
	@apply dark:!text-white;
}
.prose .warp-post-embed {
	position: relative;
	overflow: hidden;
	padding: 5px 30px 10px 30px;
	background-image: url(https://static.ouorz.com/link.png);
	background-repeat: no-repeat;
	background-position: right;
	opacity: 0.7;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.6);
	margin: 15px 0;
	@apply rounded-md border shadow-sm dark:border-gray-500 dark:bg-gray-700 dark:bg-none;
}

.prose .warp-post-embed:hover {
	opacity: 1;
}

.prose .warp-post-embed .embed-content {
	margin-right: 20%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.prose .warp-post-embed a {
	text-decoration: none;
}

.prose .warp-post-embed .embed-content p {
	display: block;
	font-size: 13px;
	font-style: normal;
	color: #888;
	@apply dark:text-gray-400;
	line-height: 1.5;
	margin-bottom: 10px;
	margin-top: 0px;
}

.prose .warp-post-embed .embed-content b {
	background: #eee;
	color: #999;
	@apply dark:text-gray-200;
	@apply dark:bg-gray-600;
	font-weight: 500 !important;
	padding: 1px 8px 3px 9px;
	border-radius: 50px;
}

.prose .warp-post-embed .embed-content h2 {
	color: rgba(0, 0, 0, 0.8);
	@apply dark:text-gray-100;
	display: block;
	margin-bottom: 8px;
	margin-top: 4%;
	font-size: 20px;
	line-height: 1.4;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.warp-post-embed .embed-bg {
	float: right;
	margin-left: 12px;
	width: 100px;
	height: 100px;
	border-radius: 10px;
	vertical-align: middle;
	box-sizing: border-box;
	background-size: contain;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
}

.prose ul li {
	@apply my-1;
}

.prose ul li::before {
	@apply bg-gray-800;
	@apply dark:bg-white;
	@apply w-3;
	@apply h-3;
	margin-top: -0.125rem;
}

.nexment-empty div {
	justify-content: center;
	display: flex;
}

.index-div {
	position: fixed;
	top: 95px;
	left: 150px;
}

.toc-active {
	background-color: #f7f8f9;
	@apply rounded-md;
	@apply dark:bg-gray-700;
	@apply text-gray-700 dark:text-gray-300;
}

.tour div:nth-child(2) {
	@apply border-l;
	@apply border-gray-200;
}

.prose h5 {
	color: #111827;
	@apply dark:text-white;
	font-weight: 600;
	margin-bottom: 0.6em;
	line-height: 1.6;
	font-size: 1.1em;
}

.prose strong {
	@apply dark:text-white;
}

.prose table {
	border: 1px solid #e5e7eb;
	@apply dark:text-white;
	@apply dark:border-gray-500;
}

.prose tbody tr {
	@apply dark:border-b-gray-500;
}

.prose tbody tr td {
	border-right: 1px solid #e5e7eb;
	@apply whitespace-nowrap;
	@apply overflow-hidden;
	@apply text-ellipsis;
	@apply dark:border-r-gray-500;
}

.prose tbody tr td:last-child {
	border-right: 0px;
}

.prose tbody tr td:first-child {
	padding-left: 0.6666667em;
}

.prose tbody tr td:last-child {
	padding-right: 0.6666667em;
}

.reader-bg {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	@apply bg-gray-100 dark:bg-gray-900;
}

.no-scrolling {
	overflow: hidden;
}

.stop-scrolling {
	width: 100%;
	position: fixed;
	overflow-y: scroll;
}

.reader {
	max-height: calc(100% - 3.8rem) !important;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	div:nth-child(1)
	.nexment-replying {
	display: flex;
}

.podcast-player {
	box-shadow: none !important;
}

.nexment-comment-area .nexment-comment-area-top {
	@apply text-4;
}

.nexment-comment-area-middle {
	@apply text-4;
}

.nexment-comment-area .nexment-comment-area-bottom div:nth-child(2) button {
	font-size: 14px !important;
}

.toc-sub {
	border-left: 1px solid rgba(229, 231, 235, 1);
	@apply rounded-tl-none;
	@apply rounded-bl-none;
}

.rhap_container {
	@apply dark:!bg-gray-800;
}

.rhap_time {
	@apply dark:!text-gray-400;
}

.rhap_progress-bar-show-download {
	@apply dark:bg-gray-700;
}

.rhap_progress-filled {
	@apply dark:bg-gray-500;
}

.rhap_download-progress {
	@apply dark:bg-gray-400;
}

.rhap_progress-indicator {
	@apply dark:bg-gray-600;
}

.rhap_main-controls-button {
	@apply dark:text-gray-500;
}

.rhap_volume-bar {
	@apply dark:bg-gray-700;
}

.rhap_volume-button {
	@apply dark:text-gray-500;
}

.rhap_volume-indicator {
	@apply dark:bg-gray-600;
}

.rhap_repeat-button {
	@apply dark:text-gray-500;
}

/* .reader::-webkit-scrollbar {
	width: 0 !important;
} */

.prose pre {
	@apply dark:bg-gray-700;
}

.prose code {
	@apply dark:text-gray-400;
}

.prose pre code::-webkit-scrollbar {
	width: 0 !important;
}

.nexment-comment-area .nexment-comment-area-top input {
	@apply dark:bg-gray-700;
	@apply dark:!border-gray-500;
}

.nexment-comment-area .nexment-comment-area-middle textarea {
	@apply dark:bg-gray-700;
	@apply dark:!border-gray-500;
}

.nexment-comment-area .nexment-comment-area-bottom div:nth-child(2) button {
	@apply dark:bg-[#115ea3];
}

.nexment-comment-area
	.nexment-comment-area-bottom
	div:nth-child(2)
	button:hover {
	@apply dark:bg-[#0f6cbd];
}

.nexment-comment-area {
	@apply dark:bg-gray-600;
	@apply dark:!border-gray-600;
}

.nexment-header h1 {
	@apply dark:!text-white;
}

.nexment-header {
	@apply items-center;
	@apply dark:!border-gray-700;
}

.nexment-comments-div .nexment-comments-title h5 {
	@apply dark:!text-white;
}

.nexment-comments-div .nexment-comments-title .nexment-comments-content p {
	@apply dark:!text-gray-100;
}

.nexment-comments-list-item {
	@apply dark:!border-gray-700;
}

.nexment-comments-reply-list {
	@apply dark:!border-gray-700;
}

.dark .nexment-inner-reply-list {
	border-color: rgba(55, 65, 81, 1) !important;
}

.nexment-comments-reply-list .nexment-comments-div:hover {
	@apply dark:!border-gray-700;
}

.react-tiny-popover-container {
	@apply dark:bg-gray-700;
	@apply dark:text-white;
	@apply dark:border-gray-500;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	div:nth-child(1)
	.nexment-replying {
	@apply dark:bg-gray-500;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	div:nth-child(1)
	.nexment-replying
	em {
	@apply dark:text-gray-300;
}

.nexment-popover-text b {
	@apply dark:text-white;
}

.nexment-comment-area .nexment-comment-area-middle .nexment-md-preview {
	@apply dark:text-white;
	@apply dark:border-gray-500;
}

.nexment-comment-area .nexment-comment-area-top input:nth-child(1) {
	@apply dark:border-r-gray-500;
}

.nexment-popover p {
	@apply dark:text-white;
	@apply mt-4;
}

.nexment-popover,
.nexment-emoji-container {
	@apply rounded-md dark:bg-gray-800;
}

.nexment-emoji-container .nexment-emoji-section .nexment-emoji-section-header {
	@apply dark:border-gray-500;
}

.nexment-emoji-container .nexment-emoji-section:nth-child(1) {
	@apply dark:border-gray-500;
}

.nexment-emoji-container
	.nexment-emoji-section
	.nexment-emoji-section-header
	b {
	@apply dark:!text-white;
}

.nexment-comments-div .nexment-comments-title h5 {
	@apply flex items-center gap-x-1;
}

.nexment-comments-replyto {
	@apply flex items-center gap-x-1;
}

.nexment-comments-div .nexment-comments-replyto svg {
	@apply !translate-y-[-1px];
}

.nexment-comments-list-item-div .rodal .rodal-dialog {
	@apply dark:bg-gray-800;
}

.nexment-reply-container .nexment-reply-primary {
	@apply dark:border-gray-500;
}

@media screen and (min-width: 1280px) {
	.nexment-comments-list-item-div .rodal .rodal-dialog {
		@apply rounded-xl;
		width: 720px !important;
	}

	.mask-x {
		mask-image: none !important;
	}
}

@media screen and (max-width: 1280px) {
	.nexment-comments-list-item-div .rodal .rodal-dialog {
		max-height: 100vh !important;
		width: 99% !important;
		height: 99% !important;
	}
}

@keyframes slideFromRight {
	from {
		opacity: 0;
		transform: translateX(10%);
	}

	to {
		opacity: 1;
		transform: translateX(0%);
	}
}

.aside {
	animation: slideFromRight 0.25s ease-in-out;
}

.aside::-webkit-scrollbar {
	width: 0 !important;
}

.wp-video {
	width: 100% !important;
	@apply rounded-md;
}

video {
	@apply rounded-md;
}

abbr[title] {
	text-decoration: none;
}

.podcast-image-placeholder {
	width: 160px !important;
	height: 160px !important;
}

.mask-y {
	-webkit-mask-image: linear-gradient(
		to bottom,
		transparent 0,
		#000 24px,
		#000 95%,
		transparent 100%
	);
	mask-image: linear-gradient(
		to bottom,
		transparent 0,
		#000 24px,
		#000 95%,
		transparent 100%
	);
}

.mask-x-r {
	-webkit-mask-image: linear-gradient(to left, transparent 0, #000 24px);
	mask-image: linear-gradient(to left, transparent 0, #000 24px);
}

.mask-x-l {
	-webkit-mask-image: linear-gradient(
		to left,
		transparent 0,
		#000 0px,
		#000 95%,
		transparent 100%
	);
	mask-image: linear-gradient(
		to left,
		transparent 0,
		#000 0px,
		#000 95%,
		transparent 100%
	);
}

.mask-x-full {
	-webkit-mask-image: linear-gradient(
		to left,
		transparent 0,
		#000 24px,
		#000 95%,
		transparent 100%
	);
	mask-image: linear-gradient(
		to left,
		transparent 0,
		#000 24px,
		#000 95%,
		transparent 100%
	);
}

.mask-x {
	-webkit-mask-image: linear-gradient(
		to left,
		transparent 0,
		#000 24px,
		#000 95%,
		transparent 100%
	);
	mask-image: linear-gradient(
		to left,
		transparent 0,
		#000 24px,
		#000 95%,
		transparent 100%
	);
}

.filter-blur {
	-webkit-backdrop-filter: saturate(180%) blur(20px);
	backdrop-filter: saturate(180%) blur(20px);
}

.tabs-highlighter {
	@apply rounded-md;
	min-height: 32px;
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.15s ease;
	transition-property: width, height, transform, opacity;
}

.kbar-mask {
	-webkit-mask-image: linear-gradient(
		to bottom,
		transparent 0,
		#000 10px,
		#000 95%,
		transparent 100%
	);
	mask-image: linear-gradient(
		to bottom,
		transparent 0,
		#000 10px,
		#000 95%,
		transparent 100%
	);
}

.glowing-area {
	padding: 1px;
}

.glowing-div {
	--x-px: calc(var(--x) * 1px);
	--y-px: calc(var(--y) * 1px);
}

.glowing-div:before,
.glowing-div::after {
	content: "";
	pointer-events: none;
	user-select: none;
	position: absolute;
	inset: 0px;
	z-index: 1;
	border-radius: inherit;
	opacity: 1;
	transition: opacity 400ms ease 0s;
	background: radial-gradient(
		500px circle at var(--x-px) var(--y-px),
		rgba(255, 255, 255, 0.3),
		transparent 40%
	);
}

.glowing-div:after {
	opacity: 0;
	z-index: 2;
}

.glowing-div:hover:after {
	opacity: 1;
}

.glowing-div-content {
	inset: 1px;
	border-radius: 0.375rem;
	z-index: 2;
	top: 1px;
	bottom: 1px;
	left: 1px;
	right: 1px;
}

.__floater {
	display: none !important;
}

.kbar-list {
	transition: height 100ms ease 0s;
	will-change: height;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.kbar-list::-webkit-scrollbar {
	display: none;
}

.kbar-list li .kbar-list-heading {
	@apply -mb-2 mt-0.5;
}

.kbar-list li:first-child .kbar-list-heading {
	@apply -mb-1 mt-0;
}

/* Workaround for React onMouseLeave not triggering when user's cursor is moving fast*/
.tabs-wrapper .tabs-highlighter {
	display: none;
}

.tabs-wrapper:hover .tabs-highlighter {
	display: block;
}

.hovering-div {
	will-change: transform;
	display: inline-block;
	transform-style: preserve-3d;
}

.overflow-wrap-breakword {
	overflow-wrap: break-word;
}

.animation-fill-mode-forwards {
	animation-fill-mode: forwards;
}

.kbar-loading-bar {
	background: linear-gradient(
		90deg,
		rgba(245, 245, 245, 0.02),
		rgba(212, 212, 212, 1) 50%,
		rgba(245, 245, 245, 0.02)
	);
}

.dark .kbar-loading-bar {
	background: linear-gradient(
		90deg,
		rgba(62, 62, 62, 0.02),
		rgba(82, 82, 82, 1) 50%,
		rgba(62, 62, 62, 0.02)
	);
}

::-webkit-scrollbar-thumb {
	@apply bg-gray-300 hover:bg-gray-400;
	border: 4px solid transparent;
	border-radius: 8px;
	background-clip: padding-box;
}

::-webkit-scrollbar {
	@apply bg-gbg;
	width: 16px;
}

.reader::-webkit-scrollbar {
	@apply bg-transparent;
}

.mask-x::-webkit-scrollbar,
.mask-y::-webkit-scrollbar {
	@apply bg-transparent;
}

@media (prefers-color-scheme: dark) {
	::-webkit-scrollbar-thumb {
		@apply bg-gray-600 hover:bg-gray-500;
	}

	::-webkit-scrollbar {
		@apply bg-neutral-900;
	}

	.reader::-webkit-scrollbar {
		@apply bg-gray-800;
	}

	.mask-x::-webkit-scrollbar,
	.mask-y::-webkit-scrollbar {
		@apply bg-gray-800;
	}
}

.nexment-comments-div .nexment-comments-title .nexment-comments-content {
	@apply dark:border-gray-600 dark:bg-gray-700;
}

.nexment-reply-container
	.nexment-comments-div
	.nexment-comments-title
	.nexment-comments-content {
	@apply dark:border-none dark:bg-transparent;
}

.nexment-comments-div .nexment-comments-title .margin-top-reply {
	@apply dark:!bg-transparent;
}

.nexment-reply-container .nexment-comments-div:hover {
	@apply dark:bg-gray-700;
}

.nexment-comments-div .nexment-comments-title h5 {
	@apply dark:text-white;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	.nexment-comment-area-toolbar
	button {
	padding-top: 6px !important;
	padding-bottom: 6px !important;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	.nexment-comment-area-submit
	button
	svg {
	margin-top: 1.5px !important;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	div:nth-child(1)
	.nexment-replying {
	padding: 3px 4px 0px 4px !important;
	transform: none !important;
	@apply dark:!border-gray-500;
}

.nexment-comment-area
	.nexment-comment-area-bottom
	div:nth-child(1)
	.nexment-replying
	b {
	margin-top: 1px !important;
}

.nexment-comment-area-container
	.nexment-comment-area
	.nexment-comment-area-top-name-avatar {
	@apply flex-shrink-0 dark:!border-gray-500 dark:bg-gray-700 dark:hover:!bg-gray-600;
}

.nexment-popover .nexment-popover-title {
	@apply dark:!border-gray-500;
}

.nexment-popover .nexment-popover-title b {
	@apply dark:!text-white;
}

.nexment-popover .nexment-popover-content p {
	@apply dark:!text-white;
}

.nexment-popover .nexment-popover-content input {
	@apply dark:!border-gray-500;
}

.nexment-popover .nexment-popover-content .nexment-popover-input {
	@apply !text-xs;
}

.nexment-popover .nexment-popover-content .nexment-popover-input input {
	@apply dark:!text-white;
}

.nexment-comment-area-container {
	@apply dark:!text-white;
}

.nexment-comment-area-toolbar button {
	@apply dark:!text-gray-300 dark:hover:!bg-gray-500;
}

.nexment-emoji-container
	.nexment-emoji-section
	.nexment-emoji-section-box
	.nexment-emoji-section-container
	span {
	@apply dark:!bg-gray-700 dark:!text-white;
}

.nexment-comment-area-container .nexment-comment-area-replying-to {
	@apply dark:!border dark:!border-gray-500;
}

.nexment-comment-area-container .nexment-comment-area-replying-to-header {
	@apply dark:!border-gray-500 dark:!text-white;
}

.nexment-comment-area-container
	.nexment-comment-area-replying-to-header
	svg
	path:nth-child(2) {
	@apply dark:!fill-white;
}

.nexment-comment-area-container .nexment-comment-area-replying-to-cta {
	@apply !px-3 dark:!border-gray-500 dark:hover:!bg-gray-700;
}

.nexment-comment-area-container .nexment-comment-area-replying-to-content p {
	@apply dark:!text-gray-300;
}

.nexment-comment-area-container
	.nexment-comment-area-replying-to-cta
	svg
	path:nth-child(2) {
	@apply dark:!fill-white;
}

.nexment-header-logo a img {
	@apply dark:rounded-sm dark:bg-gray-200 dark:p-1;
}

.nexment-comments-div .nexment-comments-title h5 a {
	@apply dark:!text-gray-200;
}

.header {
	transform: translateY(15%);
}
